import React from 'react'
import { Helmet } from 'react-helmet'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { graphql } from 'gatsby'

import bottomBg from './img/bottom-bg.svg'
import dennis from './img/dennis.png'
import middleBg from './img/middle-bg.svg'
import pgAppStore from './img/pg-app-store.png'
import pgBg from './img/pg-bg.svg'
import pgLogin from './img/pg-login.png'
import pgLogo from './img/pg-logo.svg'
import pgMain from './img/pg-main.png'
import pgPaymentHistory from './img/pg-payment-history.png'
import pgPayment from './img/pg-payment.png'
import pgPlayStore from './img/pg-play-store.png'
import topBg from './img/top-bg.svg'

const Payground = ({ data }) => {
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.paygroundBody }} />
        <div className={styles.payground}>
          <section className={`${styles.pgBgImage} ${styles.pgContentBg}`}>
            <section
              className={`${styles.flex} ${styles.container} ${styles.textContainer}`}
            >
              <section
                className={`${styles.row} ${styles.banner} ${styles.flexReverseMobile}`}
              >
                <section
                  className={`${styles.col4} ${styles.pgBgImage} ${styles.pgTopBg}`}
                >
                  <img
                    src={pgMain}
                    className={`${styles.centerBlock} ${styles.smImage}`}
                  />
                </section>
                <section className={`${styles.col8} ${styles.textContent}`}>
                  <img src={pgLogo} className={styles.bannerLogo} />
                  <p className={styles.coloredBanner}>
                    When our client, PayGround, came to us with a powerful claim
                    to fix the broken healthcare bill paying process we were
                    thrilled to take on such an important challenge together.
                    Given their depth of experience in the medical payments
                    space, PayGround was able to demonstrate that something as
                    simple as sending money to pay for a service often leads to
                    a confusing process, unnecessary calls, returned checks and
                    a lot of frustration and hair pulling by patients and their
                    families. Because there's no standardized way to pay for
                    visits and procedures, one hospital visit may result in
                    paying multiple doctors with multiple bank accounts and
                    accepted payment methods. Simply put, the process is painful
                    and the people at PayGround committed to ease the pain of
                    paying medical bills by offering an intuitive, easy-to-use
                    mobile application that streamlines the entire medical bill
                    paying process.
                  </p>
                </section>
              </section>
            </section>

            <section
              className={`${styles.flex} ${styles.container} ${styles.textContainer}`}
            >
              <section className={`${styles.row} ${styles.centerRowSm}`}>
                <section className={styles.col5}>
                  <h1>The Challenge</h1>
                  <p>
                    The client was looking to launch its first-ever
                    consumer-facing MVP mobile application to support its
                    complementary new service offering and sought Synapse for
                    assistance in developing the native application for iOS and
                    Android devices. In collaboration with a UX design firm and
                    the client’s own backend development team, we had to work
                    collaboratively and rapidly to develop the product and
                    launch on time and on budget.
                  </p>
                  <h1>Our Solution</h1>
                  <h2>Simple by Design</h2>
                  <p>
                    The PayGround app was designed to take the guess-work out of
                    paying healthcare bills with a no-nonsense user interface
                    that focuses on how little you have to use it, not how much.
                    Simply follow through selecting or adding a provider, enter
                    the patient details, and select a payment type and the app
                    handles the rest so you can do more important things.
                  </p>
                </section>
                <section
                  className={`${styles.col7} ${styles.multiImageContainer} ${styles.pgBgImage} ${styles.pgMiddleBg}`}
                >
                  <img src={pgLogin} />
                  <img src={pgPaymentHistory} />
                </section>
              </section>
            </section>

            <section
              className={`${styles.flex} ${styles.container} ${styles.textContainer}`}
            >
              <section
                className={`${styles.row} ${styles.flexMiddle} ${styles.flexReverseMobile} ${styles.centerRowSm}`}
              >
                <section className={`${styles.col7} ${styles.imageContainer}`}>
                  <img src={pgPayment} />
                </section>
                <section className={styles.col5}>
                  <h2>Uncomplicating the Process</h2>
                  <p>
                    With so much variation of payment methods accepted and
                    degrees of technical sophistication present in healthcare,
                    PayGround set out to simplify the process and so did we. We
                    worked collaboratively with the client to uncover user
                    needs, system requirements, and company goals to continually
                    align the product roadmap and delivery plans for a
                    coordinated and successful beta and launch phase.
                  </p>
                  <h2>Secure</h2>
                  <p>
                    Designed to be HIPAA-compliant, Synapse worked closely with
                    internal and external resources to ensure compliance of
                    protected information was considered at every stage of
                    development. By partnering with compliant and robust
                    third-party technology solutions such as Plaid and
                    incorporating features such as multi-factor and biometrics
                    authentication we were able to move quickly to deliver
                    powerful and secure features and functionality.
                  </p>
                </section>
              </section>
            </section>

            <section
              className={`${styles.flex} ${styles.container} ${styles.textContainer}`}
            >
              <section
                className={`${styles.row} ${styles.flexReverseMobile} ${styles.centerRowSm}`}
              >
                <section
                  className={`${styles.col6} ${styles.multiImageContainer} ${styles.pgBgImage} ${styles.pgBottomBg}`}
                >
                  <a
                    href="https://apps.apple.com/us/app/payground/id1576559175"
                    target="_blank"
                  >
                    <img src={pgAppStore} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.payground"
                    target="_blank"
                  >
                    <img src={pgPlayStore} />
                  </a>
                </section>
                <section className={styles.col6}>
                  <h1>The Result</h1>
                  <p>
                    Since launching its MVP into the market at the end of 2021,
                    the application is now live in both iOS and Android stores
                    and received positive early feedback. As a part of the
                    launch, a powerful user analytics tool and feedback suite
                    was implemented so the client can gauge and manage customer
                    sentiment and feedback as the product evolves over time.
                  </p>
                  <p>
                    Our partnership with the PayGround team was received with
                    overwhelmingly positive feedback (they extended their
                    contract with us at one point due to their satisfaction with
                    the team) and even indicated to us they plan to incorporate
                    some of the processes they learned from us as their own. We
                    wish them nothing but success in the future with this and
                    other products and hope to one day work with them again.
                  </p>
                </section>
              </section>
            </section>

            <section className={`${styles.container} ${styles.textContainer}`}>
              <aside className={styles.card}>
                <h3 className={styles.cardTitle}>Hear It From The Client</h3>
                <p className={styles.cardContent}>
                  Working with Synapse made the process of creating our mobile
                  application a breeze. We hired them to be our development
                  partner but they were so much more. They delivered on all
                  cylinders – from project management to the development stage,
                  and finishing with a strong QA process. They were a trusted
                  partner and advisor throughout the entire process – helping us
                  with user flows, design, and user experience along the way. We
                  would strongly recommend them!
                </p>
                <img src={dennis} />
                <h4 className={styles.cardTitle}>Dennis Elder</h4>
                <p>Director of Product - Mobile</p>
              </aside>
            </section>
          </section>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default Payground

export const query = graphql`
  query paygroundCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "payground-mobile-app" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
